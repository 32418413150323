import React from 'react';

function Projects() {
  const projects = [
    {
      id: 1,
      title: "React.js & Tailwind CSS Portfolio",
      description: "Created a personal portfolio website using React.js and styled with Tailwind CSS, showcasing projects and skills with a modern, responsive design.",
      image: "/images/project1.jpg",
      link: "https://krishnmehta.com",
      technologies: ["React", "Tailwind CSS", "JavaScript"]
    }
    // Add more projects here
  ];

  return (
    <section id="projects" className="min-h-screen bg-black py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-16">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold font-poppins text-white text-center mb-12">
          My Projects
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <div 
              key={project.id}
              className="group relative bg-gray-900 rounded-2xl overflow-hidden shadow-xl transform transition-all duration-500"
            >
              {/* Project Image */}
              <div className="relative h-48 sm:h-56">
                <img 
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover transition-transform duration-500"
                />
                <div className="absolute inset-0"></div>
              </div>

              {/* Project Info */}
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3 text-white">
                  {project.title}
                </h3>
                
                <p className="text-sm mb-4 text-gray-300">
                  {project.description}
                </p>
                
                {/* Technologies */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 text-xs bg-blue-900/50 rounded-full text-blue-200"
                    >
                      {tech}
                    </span>
                  ))}
                </div>

                {/* Project Link */}
                <a 
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-300"
                >
                  View Project
                  <i className="ri-arrow-right-line ml-2"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;