import React from 'react';

function About() {
  return (
    <section id="about" className="bg-black text-white min-h-screen flex items-center">
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          {/* Section Title */}
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold font-poppins mb-8 text-center lg:text-left">
            About Me
          </h1>
          
          {/* Content Paragraphs */}
          <div className="space-y-6 text-gray-300">
            <p className="text-base sm:text-lg leading-relaxed">
              I am a passionate and driven cybersecurity professional with hands-on experience in securing IT infrastructures, 
              penetration testing, and developing scalable, secure applications. I specialize in designing resilient network 
              infrastructures, uncovering vulnerabilities, and fortifying systems to ensure they stand strong against evolving threats.
            </p>
            
            <p className="text-base sm:text-lg leading-relaxed">
              My expertise spans network security, infrastructure design, and application development. I leverage programming 
              languages (C/C++, Python, Java) and web development frameworks (React, Node.js) to build secure, efficient, and 
              user-friendly solutions. Additionally, I have a strong background in database management (MySQL) and API integration, 
              ensuring comprehensive security across systems.
            </p>
            
            <p className="text-base sm:text-lg leading-relaxed">
              I enjoy working on IoT projects, exploring new technologies, and staying up-to-date with the latest trends in 
              IT security. My goal is to continue contributing to the development of secure, innovative solutions that make 
              a positive impact in the world of technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;