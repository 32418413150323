import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare template parameters
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      reply_to: formData.email // Set the "Reply-To" email dynamically
    };

    // Send email using EmailJS
    emailjs
      .send(
        'service_fwx9vc5',  // Your Service ID
        'template_bt64ku4',  // Your Template ID
        templateParams,      // Pass template parameters here
        'LOY7RilleCN0OlUXZ'   // Your User ID from EmailJS
      )
      .then(
        (result) => {
          setStatus('Message Sent!');
          setFormData({ name: '', email: '', message: '' });
          setIsModalOpen(true);  // Open the modal on success
        },
        (error) => {
          setStatus('Error: Message not sent!');
          setIsModalOpen(true);  // Open the modal on failure
        }
      );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section id="contact" className="w-full py-16 bg-black text-white">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-5xl font-bold text-center">Contact Me</h2>

        {/* Responsive Form */}
        <div className="flex justify-center">
          <form onSubmit={handleSubmit} className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 bg-black p-8 rounded-lg shadow-lg">
            <div className="mb-6">
              <label htmlFor="name" className="block text-lg font-semibold mb-2">Your Name</label>
              <input 
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-4 text-white bg-gray-800 rounded-lg focus:outline-none"
                required
              />
            </div>
            
            <div className="mb-6">
              <label htmlFor="email" className="block text-lg font-semibold mb-2">Your Email</label>
              <input 
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-4 text-white bg-gray-800 rounded-lg focus:outline-none"
                required
              />
            </div>

            <div className="mb-6">
              <label htmlFor="message" className="block text-lg font-semibold mb-2">Your Message</label>
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-4 text-white bg-gray-800 rounded-lg focus:outline-none"
                rows="6"
                required
              />
            </div>

            <button 
              type="submit" 
              className="w-full py-3 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition-all duration-300 ease-in-out"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold mb-4 text-black">{status}</h3>
            <button
              onClick={closeModal}
              className="px-6 py-2 bg-blue-600 text-black rounded-lg hover:bg-blue-500"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Contact;
