import React from 'react';

function Home() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const buttonClass = 'w-full md:w-auto px-6 py-3 text-base sm:text-lg font-semibold text-gray-200 border-2 border-blue-400 rounded-2xl hover:bg-blue-800 hover:text-white transition-all duration-300 ease-in-out mb-4 md:mb-0 sm:mr-4';
  const listItemClass = 'flex items-center space-x-2 text-sm md:text-base';

  return (
    <section id="home" className="flex flex-col md:flex-row min-h-screen bg-black text-white overflow-hidden">
      {/* Left Section - Image */}
      <div className="w-full md:w-1/2 h-64 md:h-screen relative">
        <img
          src="/images/home.jpg"
          alt="Krishn Mehta"
          className="object-cover w-full h-full md:object-center transition-all duration-500 ease-in-out transform md:hover:scale-105"
          loading="lazy"
        />
        <div className="absolute inset-0"></div>
      </div>

      {/* Right Section - Content */}
      <div className="w-full md:w-1/2 flex flex-col justify-center px-4 md:px-16 py-6 bg-gradient-to-r from-transparent to-black">
        <h1 className="text-2xl md:text-4xl font-poppins mb-4">
          Krishn Mehta – Cybersecurity Graduate
        </h1>
        
        {/* Certification */}
        <p className="text-sm md:text-lg mb-2 font-medium text-blue-400">
          Google Cybersecurity Certified
          <i className="ri-award-fill text-xl md:text-2xl text-blue-400 ml-2"></i>
        </p>
        
        {/* Introduction */}
        <p className="text-sm md:text-lg mb-6 text-gray-300 leading-relaxed">
          Welcome to my professional space! I specialize in cybersecurity, penetration testing, 
          and designing resilient network infrastructures. Driven by a passion for uncovering vulnerabilities, 
          fortifying IT systems, and crafting scalable, secure applications.
        </p>

        {/* Key Skills */}
        <div className="mb-8">
          <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400 tracking-tight mb-4">
            Core Competencies:
          </p>
          <ul className="list-none pl-0 text-gray-300 space-y-2">
            {[
              "Network Security & Infrastructure",
              "Penetration Testing & Vulnerability Assessment",
              "Windows/Linux Security",
              "Programming (C/C++, Python, PHP, Java, React)",
              "Web Development & Server Management",
              "Database Management & Optimization (MySQL)",
              "IoT Projects & API Integration"
            ].map((skill, index) => (
              <li key={index} className={listItemClass}>
                <i className="ri-check-fill text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400"></i>
                <span>{skill}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Call to Action */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <button
            onClick={() => scrollToSection('projects')}
            className={buttonClass}
          >
            View My Projects
          </button>
          <button
            onClick={() => scrollToSection('contact')}
            className={buttonClass}
          >
            Contact Me
          </button>
        </div>
      </div>
    </section>
  );
}

export default Home;