import React from 'react';
import 'remixicon/fonts/remixicon.css';

function Footer() {
  return (
    <footer className="bg-black text-white py-8">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Website Information */}
          <div className="text-center md:text-left">
            <p className="text-2xl font-semibold font-poppins">Krishn Mehta</p>
            <p className="mt-2 text-lg text-gray-300">
              Cybersecurity Graduate | Google Cybersecurity Certified
            </p>
            <p className="mt-2 text-lg text-gray-300">
              Specialized in Network Security, Penetration Testing, and IT Infrastructure
            </p>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-6 mt-6 md:mt-0">
            <a 
              href="https://github.com/krishn-mehta" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-blue-400 transition duration-300"
            >
              <i className="ri-github-fill text-3xl"></i>
            </a>
            <a 
              href="https://www.linkedin.com/in/krishn-mehta/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-blue-400 transition duration-300"
            >
              <i className="ri-linkedin-box-fill text-3xl"></i>
            </a>
            <a 
              href="https://coursera.org/verify/professional-cert/S47AJZF4RKTW" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-blue-400 transition duration-300"
            >
              <i className="ri-award-fill text-3xl"></i>
            </a>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2024 Krishn Mehta. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;