import React, { useState, useEffect } from 'react';
import 'remixicon/fonts/remixicon.css';

function Navbar() {
  const [active, setActive] = useState('home');
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleActiveSection = () => {
      const sections = ['home', 'about', 'skills', 'projects', 'contact'];
      const scrollY = window.scrollY;

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const sectionTop = element.offsetTop - 100;
          const sectionBottom = sectionTop + element.offsetHeight;
          if (scrollY >= sectionTop && scrollY < sectionBottom) {
            setActive(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleActiveSection);
    return () => window.removeEventListener('scroll', handleActiveSection);
  }, []);

  const navLinks = ['home', 'about', 'skills', 'projects', 'contact'];
  const socialLinks = [
    { 
      icon: "ri-github-fill", 
      url: "https://github.com/krishn-mehta",
      label: "GitHub"
    },
    { 
      icon: "ri-linkedin-box-fill", 
      url: "https://www.linkedin.com/in/krishn-mehta/",
      label: "LinkedIn"
    },
    { 
      icon: "ri-award-fill", 
      url: "https://coursera.org/verify/professional-cert/S47AJZF4RKTW",
      label: "Certification"
    }
  ];

  return (
    <nav className={`fixed w-full top-0 left-0 z-50 transition-all duration-300 
      ${scrolled ? 'bg-black/90 backdrop-blur-sm' : 'bg-transparent'}`}>
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <a href="#home" 
             onClick={() => setActive('home')}
             className="text-2xl font-semibold font-poppins text-white hover:text-blue-400 transition-colors">
            Krishn Mehta
          </a>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {/* Navigation Links */}
            {navLinks.map((section) => (
              <a
                key={section}
                href={`#${section}`}
                onClick={() => setActive(section)}
                className={`transition-colors ${
                  active === section
                    ? 'text-blue-400' 
                    : 'text-white hover:text-blue-400'
                }`}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </a>
            ))}

            {/* Social Links */}
            <div className="flex items-center space-x-4 ml-4">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-blue-400 transition-colors duration-300"
                  aria-label={link.label}
                >
                  <i className={`${link.icon} text-2xl`}></i>
                </a>
              ))}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="md:hidden text-white"
          >
            <i className={`ri-${menuOpen ? 'close' : 'menu'}-line text-2xl`}></i>
          </button>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className="md:hidden pt-4">
            <div className="flex flex-col space-y-4">
              {navLinks.map((section) => (
                <a
                  key={section}
                  href={`#${section}`}
                  onClick={() => {
                    setActive(section);
                    setMenuOpen(false);
                  }}
                  className={`text-center py-2 transition-colors ${
                    active === section
                      ? 'text-blue-400'
                      : 'text-white hover:text-blue-400'
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </a>
              ))}
              
              {/* Social Links in Mobile Menu */}
              <div className="flex justify-center space-x-6 pt-4 pb-2">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:text-blue-400 transition-colors duration-300"
                    aria-label={link.label}
                  >
                    <i className={`${link.icon} text-2xl`}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;