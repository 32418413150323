import React from 'react';

function Skills() {
  const skills = [
    {
      title: "Web Development & Server Management",
      description: "Skilled in building secure web applications using React, Node.js, PHP, and JavaScript. Proficient in Apache2 server management.",
      icon: "ri-code-line"
    },
    {
      title: "Windows/Linux Security",
      description: "Proficient in configuring and maintaining both Windows and Linux systems, focusing on security and performance.",
      icon: "ri-shield-check-line"
    },
    {
      title: "Programming Languages",
      description: "Skilled in C/C++, Python, PHP, Java, and MySQL, developing secure and efficient software solutions.",
      icon: "ri-terminal-box-line"
    },
    {
      title: "Network Security & Monitoring",
      description: "Expert in securing networks using firewalls, VPNs, IDS/IPS, and monitoring tools to detect and prevent threats.",
      icon: "ri-radar-line"
    },
    {
      title: "Penetration Testing",
      description: "Hands-on experience in ethical hacking, vulnerability assessments, and penetration testing.",
      icon: "ri-bug-line"
    },
    {
      title: "API Integration & Development",
      description: "Experienced in designing, developing, and integrating secure APIs for seamless system communication.",
      icon: "ri-cloud-line"
    }
  ];

  return (
    <section id="skills" className="py-16 md:py-24 bg-black">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold font-poppins text-white text-center mb-12">
          My Skills
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {skills.map((skill, index) => (
            <div 
              key={index} 
              className="bg-gray-900 rounded-xl p-6 transition-all duration-300 transform hover:scale-105"
            >
              <div className="flex items-center mb-4">
                <i className={`${skill.icon} text-2xl text-blue-400`}></i>
                <h3 className="text-xl font-semibold ml-3 text-white">
                  {skill.title}
                </h3>
              </div>
              <p className="text-sm leading-relaxed text-gray-300">
                {skill.description}
              </p>
            </div>
          ))}
        </div>

        {/* Skill Level Indicators */}
        <div className="mt-16 max-w-3xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {[
              { name: "Frontend Development", level: 85 },
              { name: "Backend Security", level: 90 },
              { name: "Network Security", level: 95 },
              { name: "Penetration Testing", level: 88 }
            ].map((skill, index) => (
              <div key={index} className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-300">
                    {skill.name}
                  </span>
                  <span className="text-sm font-medium text-blue-400">
                    {skill.level}%
                  </span>
                </div>
                <div className="h-2 rounded-full bg-gray-700">
                  <div 
                    className="h-full rounded-full bg-gradient-to-r from-blue-400 to-blue-600"
                    style={{ width: `${skill.level}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;